import { RefObject } from 'react';

export interface IPoint {
  x: number,
  y: number,
  x1: number,
  y1: number,
  pageIndex: number,
}

export enum RedactionEntityType {
  Area = 'area',
  Text = 'text',
  Search = 'search',
}

export enum RedactionType {
  Drawing,
  TextSelection,
  Search,
}

export interface IPropsRedactionTool {
  isEnabled: boolean,
  canvasRef: RefObject<HTMLCanvasElement>,
  scale: number,
  handleInsertShapes: (shapes: IRedactionTool[]) => void,
  handleUpdateShape: (id: string) => void,
}

export interface RedactionFrame {
  topLeftX: number,
  topLeftY: number,
  bottomRightX: number,
  bottomRightY: number,
  pageIndex: number,
}

export interface IRedactionEntity {
  redactionId: string,
  redactionType: RedactionEntityType,
  redactionLabel: string,
  redactionUpdatedAt: string,
  entryTitle: string,
  frames: RedactionFrame[],
}

export interface IRedactionListItem {
  title: string,
  count?: number,
  id: string,
  type: RedactionEntityType,
  pageIndex?: number,
  controlPoints?: any[],
  meta: any,
  redactionState: DrawingState,
}

export type ISerializedShape = ReturnType<IRedactionTool['serialize']>;

export enum DrawingState {
  Draft,
  Final,
}

export interface IRedactionTool<T extends RedactionEntityType = RedactionEntityType> {
  id: string, // Identification for deleting
  ctx: { [pageIndex: number]: CanvasRenderingContext2D }, // Canvas context for drawing.
  type: RedactionEntityType, // Type of redaction tool. It is used on serialization.
  isActive: boolean, // Whether current shape is selected.
  isToolActive: boolean, // Whether current tool is selected.
  isDragging?: boolean, // Whether current shape is in dragging state.
  isHovered: boolean, // Whether current shape is hovered.
  activeColor: string, // Color of active shape.
  strokeColor: string, // Color of strokes on canvas for current shape.
  strokeWidth: number, // Size of strokes on canvas for current shape.
  defaultColor: string,
  fillColor: string, // Color of filling background on canvas for current shape.
  requiredControlPoints: number, // Required amount of control point to build a shape.
  meta: any, // Meta information for the shape.
  controlPoints: ControlPointsType<T>, // Array of control points.
  addControlPoint: AddControlPointType<T>, // Adds control point to the array of control points.
  drawingState?: DrawingState, // State of drawing. It is used on serialization.
  controlPointSize?: number, // Size of control point. Will be rendered on canvas when the shape is active.
  deviation: number, // Permissible deviation when choosing the shape on canvas.
  diameterCloseButton: number, // Diameter of close button. Will be rendered on canvas when the shape is hovered.
  bindContext: (canvas: HTMLCanvasElement, pageIndex: number) => void, // Binds canvas context.
  move?: (deltaX: number, deltaY: number) => void, // Move the shape.
  resize?: (controlPointIndex: number, x: number, y: number) => void, // Changing position of specific control point.
  draw: DrawType<T>, // Draw the shape
  drawControlPoints?: () => void, // Draw control points when the shape is active.
  drawDeleteButton: () => void, // Draw delete button when the shape is active.
  isShapeArea: (x: number, y: number, pageIndex: number) => boolean, // Whether passed coordinates is on the shape area.
  isDeleteButtonArea: (
    x: number,
    y: number,
    pageIndex?: number,
  ) => boolean, // Whether passed coordinates is on the delete button area.
  getHandleIndex?: (x: number, y: number) => number | null, // Gets handle index by coordinates according deviation.
  isFinished?: () => boolean, // Whether the shape has all required control points to render.
  setFillColor: (color: string) => void,
  setIsHovered: (value: boolean) => void,
  setMetadata: (meta: any) => void,
  setId: (id: string) => void, // Set id for the shape.
  setToolActive: (value: boolean) => void,
  setIsActive: (value: boolean) => void,
  setDrawingState?: (drawingState: DrawingState) => void,
  setIsDragging?: (value: boolean) => void,
  serialize: () => {
    type: IRedactionTool['type'],
    controlPoints: IRedactionTool['controlPoints'],
    id: IRedactionTool['id'],
    meta: IRedactionTool['meta'],
    drawingState: IRedactionTool['drawingState'],
  }, // Serialize shape.
}

type ControlPointsType<T> = T extends RedactionEntityType.Area ? Partial<IPoint>[] : IPoint[];

type AddControlPointType<T> = T extends RedactionEntityType.Area
  ? (controlPoint: Partial<IPoint>) => void
  : (controlPoint: IPoint) => void;

type DrawType<T> = T extends RedactionEntityType.Area
  ? (nextPoint?: Partial<IPoint>) => void
  : () => void;
