import { useState } from 'react';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { useDIContext } from '@/Framework/DI/DIContext';
import RedactionRepository from '@/dataroom/infrastructure/repository/RedactionRepository';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { IRedactionEntity } from '@/ui/shared/modules/DocumentViewer/plugins/RedactionPlugin/types';

export default function useListing() {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [redactions, setRedactions] = useState<IRedactionEntity[]>([]);

  const getRedactions = async (fileId: number) => {
    setIsFetching(true);

    try {
      const payload = {
        dataroomId: dataroom.id,
        fileId,
      };

      const redactionRepository = container.get<RedactionRepository>(RedactionRepository);

      const { collection } = await redactionRepository.getRedactions(payload);
      setRedactions(collection);
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsFetching(false);
    }
  };

  const resetRedactions = () => {
    setRedactions([]);
  };

  return {
    isFetching,
    redactions,
    getRedactions,
    resetRedactions,
  };
}
