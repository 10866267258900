import React, { useState } from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { IconType } from '@dealroadshow/uikit';
import { ContextMenu as ContextMenuContainer, MenuItem } from '@/dataroom/ui/common/ContextMenu';
import useDisableModal from '@/dataroom/ui/common/DataroomExplorer/Modals/DisableModal/useDisableModal';
import useDeleteModal from '@/dataroom/ui/common/DataroomExplorer/Modals/DeleteModal/useDeleteModal';
import DeleteFileSystemItemsForm
  from '@/dataroom/ui/common/DataroomExplorer/Modals/DeleteModal/DeleteFileSystemItemsForm';
import useEditModal from '@/dataroom/ui/common/DataroomExplorer/Modals/EditModal/useEditModal';
import { contextMenuId } from './constants';
import * as filesystemPermissions from '@/dataroom/domain/filesystemPermissions';
import * as managePermissions from '@/dataroom/domain/managePermissions';
import * as questionPermissions from '@/dataroom/domain/questionPermissions';
import { isFilesystemPreparingForArchive, isFileIndexingEnabled, isUrl } from '@/dataroom/domain/filesystem';
import { Area } from '@/dataroom/domain/vo/Area';
import PortalWrp from '@/ui/shared/components/Layout/PortalWrp';
import useAskQuestionModal from '@/dataroom/ui/components/Dataroom/components/AskQuestionModal/useAskQuestionModal';
import useCopyModal from '@/dataroom/ui/common/DataroomExplorer/Modals/CopyMoveModal/useCopyModal';
import useMoveModal from '@/dataroom/ui/common/DataroomExplorer/Modals/CopyMoveModal/useMoveModal';
import useChangeIndexModal
  from '@/dataroom/ui/common/DataroomExplorer/Modals/ChangeIndexModal/useChangeIndexModal';
import useCreateFolderModal
  from '@/dataroom/ui/common/DataroomExplorer/Modals/CreateFolderModal/useCreateFolderModal';
import useCreateLinkModal from '@/dataroom/ui/common/DataroomExplorer/Modals/CreateLinkModal/useCreateLinkModal';
import usePhysicalCopyModal from '@/dataroom/ui/components/dmPortal/Modals/PhysicalCopyModal/usePhysicalCopyModal';
import { usePrimaryFolderTreeContext } from '@/dataroom/application/folderTree/PrimaryFolderTreeContext';
import { useStagingFolderTreeContext } from '@/dataroom/application/folderTree/StagingFolderTreeContext';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import PreviewButton from '@/dataroom/ui/common/DataroomExplorer/Columns/Actions/PreviewButton';
import BookmarkButton from '@/dataroom/ui/common/DataroomExplorer/Columns/Actions/BookmarkButton';
import CopyLinkButton from '@/dataroom/ui/common/DataroomExplorer/Columns/Actions/CopyLinkButton';
import DownloadButton from '@/dataroom/ui/common/Download/DownloadButton';
import { variantTypes } from '@/ui/shared/components/Button/ActionButton/constants';
import InfoButton from '@/dataroom/ui/common/DataroomExplorer/Actions/InfoButton';
import ContextMenuItem from './ContextMenuItem';
import styles from './contextMenu.scss';
import { IFolderTree } from '@/dataroom/domain/vo/filesystem/FolderTree';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { useDnDFileIndexesContext } from '@/dataroom/application/DnDFileIndexesContext';
import FeatureToggle from '@/ui/shared/modules/FeatureToggle/Components';

interface IProps {
  id: string,
}

const ContextMenu = ({ id = contextMenuId }: IProps) => {
  const [menuItems, setMenuItems] = useState([]);

  const { currentUser } = useCurrentUserContext();
  const { dataroom } = useDataroomContext();
  const { turnOnDnDFileIndexesMode, isFileIndexesButtonDisabled } = useDnDFileIndexesContext();

  const userCanBulkDownload = managePermissions.canUserBulkDownload(currentUser);
  const userHasSystemManageAccess = managePermissions.canManageSettings(currentUser);
  const userIsDefaultAdmin = managePermissions.isDefaultAdmin(currentUser);
  const isPreparingForArchive = isFilesystemPreparingForArchive();

  const {
    showDeleteModal,
    hideDeleteModal,
    deleteItems,
    DeleteModal,
  } = useDeleteModal();

  const {
    showAskQuestionModal,
    hideAskQuestionModal,
    selectedFile,
    isQuestionModalVisible,
    AskQuestionModal,
  } = useAskQuestionModal();

  const {
    showDisableModal,
    hideDisableModal,
    disableItems,
    DisableModal,
  } = useDisableModal();

  const {
    showEditModal,
    hideEditModal,
    filesystemEditItem,
    EditModal,
  } = useEditModal();

  const {
    showCopyModal,
    hideCopyModal,
    filesystemCopyItems,
    CopyModal,
  } = useCopyModal();

  const {
    showMoveModal,
    hideMoveModal,
    filesystemMoveItems,
    MoveModal,
  } = useMoveModal();

  const {
    showChangeIndexModal,
    hideChangeIndexModal,
    changeIndexItem,
    ChangeIndexModal,
  } = useChangeIndexModal();

  const {
    showCreateFolderModal,
    hideCreateFolderModal,
    parentFolder,
    CreateFolderModal,
  } = useCreateFolderModal();

  const {
    showCreateLinkModal,
    hideCreateLinkModal,
    isVisible: isCreateLinkModalVisible,
    CreateLinkModal,
  } = useCreateLinkModal();

  const {
    Modal: PhysicalCopyModal,
    dataroomItem: dataroomPhysicalCopyItem,
    showPhysicalCopyModal,
    hidePhysicalCopyModal,
  } = usePhysicalCopyModal();

  const { primaryFolderTree } = usePrimaryFolderTreeContext();
  const { stagingFolderTree } = useStagingFolderTreeContext();
  const getAllowedMenuActions = (item: IFolderTree | IFilesystemListItem = null, parentFolder, selected) => {
    const area = (item || parentFolder)?.isStaging ? Area.Staging : Area.Primary;
    const selectedItems = selected?.length ? selected : [item];
    const canPreview = filesystemPermissions.canPreview(item);
    const canCreateFolder = filesystemPermissions.canCreateFolder(item || parentFolder);
    const canCreateLink = filesystemPermissions.canCreateLink(!item && parentFolder);
    const canDownload = filesystemPermissions.canDownload(item, userCanBulkDownload, isPreparingForArchive, 0);
    const canCopyAndMove = filesystemPermissions.canCopyAndMove(
      item, [primaryFolderTree, stagingFolderTree], [],
    );
    const canChangeIndex = filesystemPermissions.canChangeIndex(
      item,
      [],
      isFileIndexingEnabled(dataroom, area),
      userHasSystemManageAccess,
    );
    const canChangeIndexes = managePermissions.canUserManageOwnSettings(currentUser)
      && isFileIndexingEnabled(dataroom, area)
      && !selected?.length && !item;
    const canEdit = filesystemPermissions.canEdit(item, 0);
    const canDelete = filesystemPermissions.canDelete(item);
    const canDisable = filesystemPermissions.canDisable([item], userHasSystemManageAccess);
    const canRedact = userIsDefaultAdmin && filesystemPermissions.canRedact(item, 0);
    const canSubmitQuestion = dataroom.qnaModule && questionPermissions.canSubmitQuestion(currentUser);
    const isAllLimitsReached = currentUser.qnaIsLimitReached;
    const { canRequestPhysicalCopy } = dataroom;

    const data = { item, parentFolder, selected };

    return [
      {
        key: 'preview',
        dataTest: 'previewButton',
        title: (
          <PreviewButton
            variant={ variantTypes.textWithIcon }
            // @ts-ignore TODO fix, this is the place for possible error
            item={ item }
            dataTest="previewButton"
          />
        ),
        className: styles.actionButtonMenuItem,
        isAllowed: canPreview,
      },
      {
        key: 'createFolder',
        dataTest: 'createFolderButton',
        title: (
          <ContextMenuItem
            title="Create Folder"
            iconType={ IconType.folderAdd }
          />
        ),
        action: showCreateFolderModal,
        isAllowed: canCreateFolder,
      },
      {
        key: 'createLink',
        dataTest: 'createLinkButton',
        title: (
          <ContextMenuItem
            title="Create Link"
            iconType={ IconType.linkAdd }
          />
        ),
        action: showCreateLinkModal,
        isAllowed: canCreateLink,
      },
      {
        key: 'changeIndexesButton',
        dataTest: 'changeIndexesButton',
        title: (
          <ContextMenuItem
            title="Change Index #"
            iconType={ IconType.fileIndex }
            isDisabled={ isFileIndexesButtonDisabled }
          />
        ),
        action: isFileIndexesButtonDisabled ? null : () => turnOnDnDFileIndexesMode(parentFolder?.isStaging),
        isAllowed: canChangeIndexes,
      },
      {
        key: 'download',
        dataTest: 'downloadButton',
        title: (
          <DownloadButton
            variant={ variantTypes.textWithIcon }
            selected={ selectedItems }
            areas={ [selectedItems[0]?.isStaging ? Area.Staging : Area.Primary] }
            dataTest="downloadButton"
          />
        ),
        className: styles.actionButtonMenuItem,
        isAllowed: canDownload,
      },
      {
        key: 'requestPhysicalCopy',
        dataTest: 'requestPhysicalCopyButton',
        title: (
          <ContextMenuItem
            title="Request Physical Copy"
            iconType={ IconType.download }
          />
        ),
        action: () => showPhysicalCopyModal(dataroom),
        isAllowed: item && canRequestPhysicalCopy,
      },
      {
        key: 'primarySeparator',
        isAllowed: canPreview || canCreateFolder || canCreateLink || canDownload,
      },
      {
        key: 'question',
        dataTest: 'questionButton',
        title: (
          <ContextMenuItem
            title="Ask a Question"
            iconType={ IconType.ask }
            isDisabled={ isAllLimitsReached }
          />
        ),
        action: !isAllLimitsReached && showAskQuestionModal,
        isAllowed: item && !item.isStaging && !isUrl(item) && canSubmitQuestion,
      },
      {
        key: 'bookmark',
        dataTest: 'bookmarkButton',
        title: (
          <BookmarkButton
            variant={ variantTypes.textWithIcon }
            // @ts-ignore TODO fix, this is the place for possible error
            item={ item }
            dataTest="bookmarkButton"
          />
        ),
        className: styles.actionButtonMenuItem,
        isAllowed: item && primaryFolderTree && (item.id !== primaryFolderTree.id),
      },
      {
        key: 'copyLink',
        dataTest: 'CopyLinkButton',
        title: (
          <CopyLinkButton
            item={ item }
            dataTest="copyLinkButton"
            isMenuVisible={ !!id }
            className={ styles.copyLinkButton }
          />
        ),
        className: styles.actionButtonMenuItem,
        isAllowed: item,
      },
      {
        key: 'info',
        dataTest: 'infoButton',
        title: (
          <InfoButton
            item={ item || parentFolder }
            dataTest="infoButton"
            variant={ variantTypes.textWithIcon }
            className={ styles.infoButton }
            title="View Details"
          />
        ),
        className: styles.actionButtonMenuItem,
        isAllowed: true,
      },
      {
        key: 'infoSeparator',
        isAllowed: true,
      },
      {
        key: 'copy',
        dataTest: 'copyButton',
        title: (
          <ContextMenuItem
            title="Copy"
            iconType={ IconType.copy }
          />
        ),
        action: showCopyModal,
        isAllowed: canCopyAndMove,
      },
      {
        key: 'changeIndex',
        dataTest: 'changeIndexButton',
        title: (
          <ContextMenuItem
            title="Change Index #"
            iconType={ IconType.list }
          />
        ),
        action: showChangeIndexModal,
        isAllowed: canChangeIndex,
      },
      {
        key: 'edit',
        dataTest: 'editButton',
        title: (
          <ContextMenuItem
            title="Edit"
            iconType={ IconType.pencil }
          />
        ),
        action: showEditModal,
        isAllowed: canEdit,
      },
      {
        key: 'move',
        dataTest: 'moveButton',
        title: (
          <ContextMenuItem
            title="Move"
            iconType={ IconType.move }
          />
        ),
        action: showMoveModal,
        isAllowed: canCopyAndMove,
      },
      {
        key: 'redact',
        dataTest: 'redactButton',
        title: (
          <FeatureToggle featureName="dataroomRedaction">
            <PreviewButton
              variant={ variantTypes.textWithIcon }
            // @ts-ignore TODO fix, this is the place for possible error
              item={ item }
              dataTest="redactButton"
              title="Redact"
              icon={ IconType.redaction }
              mode="redact"
            />
          </FeatureToggle>
        ),
        className: styles.actionButtonMenuItem,
        isAllowed: canRedact,
      },
      {
        key: 'mainSeparator',
        isAllowed: canCopyAndMove || canChangeIndex || canEdit,
      },
      {
        key: 'delete',
        dataTest: 'deleteButton',
        title: (
          <ContextMenuItem
            title="Delete"
            iconType={ IconType.trash }
            isWarning
          />
        ),
        action: showDeleteModal,
        isAllowed: canDelete,
      },
      {
        key: 'disable',
        dataTest: 'disableButton',
        title: (
          <ContextMenuItem
            title="Disable"
            iconType={ IconType.disable }
            isWarning
          />
        ),
        action: showDisableModal,
        isAllowed: canDisable,
      },
    ].map(
      (item) => ({ ...item, data }),
    ).filter(
      (action) => (action.isAllowed),
    );
  };

  const handleFilterMenuItems = (e) => {
    const { fileSystemItem, parentFolder, selected } = e.detail.data;
    setMenuItems(
      getAllowedMenuActions(fileSystemItem, parentFolder, selected),
    );
  };

  const menu = menuItems.map(({ key, className, action, data, title, dataTest }) => {
    if (title) {
      return (
        <MenuItem
          key={ key }
          className={ className }
          onClick={ () => {
            action && action(data.item || data.parentFolder);
          } }
          dataTest={ dataTest }
        >
          { title }
        </MenuItem>
      );
    }
    return (
      <div
        key={ key }
        className={ styles.separator }
      />
    );
  });

  return (
    <>
      <DeleteModal
        items={ deleteItems }
        closeModal={ hideDeleteModal }
      >
        { (props) => (<DeleteFileSystemItemsForm { ...props } />) }
      </DeleteModal>
      <DisableModal
        items={ disableItems }
        closeModal={ hideDisableModal }
      />
      { isQuestionModalVisible && (
        <AskQuestionModal
          isVisible
          file={ selectedFile }
          closeModal={ hideAskQuestionModal }
        />
      ) }
      <EditModal
        item={ filesystemEditItem }
        closeModal={ hideEditModal }
      />
      <MoveModal
        items={ filesystemMoveItems }
        closeModal={ hideMoveModal }
      />
      <CopyModal
        items={ filesystemCopyItems }
        closeModal={ hideCopyModal }
      />
      <ChangeIndexModal
        item={ changeIndexItem }
        closeModal={ hideChangeIndexModal }
      />
      <CreateFolderModal
        parentFolder={ parentFolder }
        closeModal={ hideCreateFolderModal }
      />
      <CreateLinkModal
        isVisible={ isCreateLinkModalVisible }
        closeModal={ hideCreateLinkModal }
      />
      <PhysicalCopyModal
        dataroom={ dataroomPhysicalCopyItem }
        onClose={ hidePhysicalCopyModal }
      />
      { /* @ts-ignore */ }
      <PortalWrp>
        <ContextMenuContainer
          id={ id }
          className={ cn(styles.contextMenu, {
            [styles.hiddenContextMenu]: isEmpty(menuItems),
          }) }
          onShow={ handleFilterMenuItems }
        >
          { menu }
        </ContextMenuContainer>
      </PortalWrp>
    </>
  );
};

export default ContextMenu;
