import React, { Component, createRef } from 'react';
import cn from 'classnames';
import noop from 'lodash/noop';
import { IFinalFormFieldInputComponentProps } from '@/ui/shared/components/Form/FinalForm/interfaces';
import { FormError, FormField, InputLabel as Label, Icon, IconType } from '@dealroadshow/uikit';
import styles from '@dealroadshow/uikit/dist/lib/components/Forms/Input/input.scss';

interface IProps extends IFinalFormFieldInputComponentProps {
  name: string,
  type?: 'number' | 'text' | 'date' | 'time' | 'email' | 'password' | 'hidden',
  label?: React.ReactNode,
  placeholder?: string,
  value?: string | number,
  className?: string,
  inputClassName?: string,
  iconPosition?: 'left' | 'right',
  iconType?: IconType,
  size?: 'small' | 'medium',
  minLength?: number,
  maxLength?: number,
  min?: number,
  max?: number,
  iconClassName?: string,
  disabled?: boolean,
  isReadOnly?: boolean,
  isNarrow?: boolean,
  isErrorMessage?: boolean,
  isClearable?: boolean,
  autoFocus?: boolean,
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onClick?: React.MouseEventHandler,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onKeyPress?: React.KeyboardEventHandler,
  prependComponent?: React.ReactElement | React.ElementType | React.FC<any>,
  appendComponent?: React.ReactElement | React.ElementType | React.FC<any>,
  isLastPassAutofillEnabled?: boolean,
  formFieldClassName?: string,
  dataTest: string,
  autocomplete?: string,
}

interface IState {
  isFocused: boolean,
  isHovered: boolean,
}

const defaultProps: Partial<IProps> = {
  type: 'text',
  iconPosition: 'left',
  disabled: false,
  isReadOnly: false,
  isNarrow: false,
  isErrorMessage: true,
  isClearable: true,
  autoFocus: false,
  value: '',
  size: 'medium',
  isLastPassAutofillEnabled: false,
  onBlur: noop,
  onClick: noop,
  onChange: noop,
  onFocus: noop,
  onKeyPress: noop,
};

class Input extends Component<IProps, IState> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps: Partial<IProps>;

  // eslint-disable-next-line react/sort-comp
  private nodeInput: any;

  constructor(props: IProps) {
    super(props);

    this.state = {
      isFocused: false,
      isHovered: false,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.value = this.value.bind(this);

    this.nodeInput = createRef();
  }

  /**
   * Can be called by ref.focus()
   */
  focus = (event?: React.FocusEvent<HTMLInputElement>) => {
    /**
     * In React 17t he old event pooling optimization has been fully removed,
     * so you can read the event fields whenever you need them.
     * TODO remove persist after React update
     */
    if (event && 'persist' in event) {
      event.persist();
    }

    this.setState({
      isFocused: true,
    });

    if (this.props.input && ('onFocus' in this.props.input)) {
      this.props.input.onFocus(event);
    }
    if (this.nodeInput.current) {
      this.nodeInput.current.focus();
    }

    return this.props.onFocus(event);
  };

  onBlur = (event?: React.FocusEvent<HTMLInputElement>) => {
    if (!this.state.isHovered) {
      this.setState({
        isFocused: false,
      });
    }
    /**
     * In React 17t he old event pooling optimization has been fully removed,
     * so you can read the event fields whenever you need them.
     * TODO remove persist after React update
     */
    event.persist();

    if (this.props.input && ('onBlur' in this.props.input)) {
      this.props.input.onBlur(event);
    }

    return this.props.onBlur(event);
  };

  value() {
    if (this.props.input && ('value' in this.props.input)) {
      return this.props.input.value;
    }
    return this.props.value;
  }

  handleHoverOn = () => {
    this.setState({ isHovered: true });
  };

  handleHoverOff = () => {
    this.setState({ isHovered: false });
  };

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement> | string) => {
    if (this.props.input && ('onChange' in this.props.input)) {
      this.props.input.onChange(event);
    }
    return this.props.onChange({
      // @ts-ignore
      target: {
        value: ((event instanceof Object) ? event.target.value : event),
      },
    });
  };

  renderPrependComponent = () => {
    const {
      prependComponent: PrependComponent,
      onClick,
      iconType,
      iconPosition,
      iconClassName,
      size,
    } = this.props;
    if (PrependComponent || ((iconPosition === 'left') && iconType)) {
      return (
        <div className={ cn(styles.prependComponent, { [styles.small]: size === 'small' }) }>
          { /* eslint-disable-next-line no-nested-ternary */ }
          { PrependComponent
            // @ts-ignore
            ? (React.isValidElement(PrependComponent) ? PrependComponent : <PrependComponent />)
            : (
              <>
                { (iconPosition === 'left') && iconType && (
                  <div className={ styles.iconPositionLeft }>
                    <Icon
                      onClick={ onClick }
                      type={ iconType }
                      className={ iconClassName }
                    />
                  </div>
                ) }
              </>
            ) }
        </div>
      );
    }

    return null;
  };

  renderAppendComponent = () => {
    const {
      appendComponent: AppendComponent,
      onClick,
      isClearable,
      iconType,
      iconPosition,
      iconClassName,
      size,
      disabled,
    } = this.props;

    if (AppendComponent || ((iconPosition === 'right') && iconType) || isClearable) {
      return (
        <div className={ cn(styles.appendComponent, { [styles.small]: size === 'small' }) }>
          { /* eslint-disable-next-line no-nested-ternary */ }
          { AppendComponent
            ? (
              // @ts-ignore
              React.isValidElement(AppendComponent) ? AppendComponent : <AppendComponent />
            )
            : (
              <>
                {
                  (
                    !disabled &&
                    isClearable &&
                    (this.state.isHovered || this.state.isFocused) &&
                    this.value().toString().length > 0
                  )
                  ? (
                    <div
                      className={ cn(styles.clearableBtn, { [styles.small]: size === 'small' }) }
                      onClick={ () => this.handleOnChange('') }
                    >
                      <Icon type={ IconType.close } />
                    </div>
                  ) : (
                    <>
                      { (iconPosition === 'right') && iconType && (
                        <div className={ styles.iconPositionRight }>
                          <Icon
                            onClick={ onClick }
                            type={ iconType }
                            className={ iconClassName }
                          />
                        </div>
                      ) }
                    </>
                  )
                }
              </>
            ) }
        </div>
      );
    }

    return null;
  };

  /**
   * @return {ReactElement}
   */
  render() {
    let {
      input,
      meta,
      type,
      name,
      label,
      placeholder,
      className,
      inputClassName,
      iconType,
      prependComponent: PrependComponent,
      appendComponent: AppendComponent,
      iconPosition,
      isNarrow,
      isErrorMessage,
      isClearable,
      disabled,
      isReadOnly,
      autoFocus,
      maxLength,
      minLength,
      max,
      min,
      size,
      isLastPassAutofillEnabled,
      onClick,
      onBlur,
      onKeyPress,
      formFieldClassName,
      dataTest,
      autocomplete,
    } = this.props;
    const { isHovered, isFocused } = this.state;
    const value = this.value();
    const isClearIconVisible = isClearable && value?.toString().length > 0 && !disabled && (isHovered || isFocused);

    const inputWrapperCls = cn(styles.formInputWrp, {
      [styles.isPrependComponent]: PrependComponent || ((iconPosition === 'left') && iconType),
      [styles.isAppendComponent]: AppendComponent || ((iconPosition === 'right') && iconType) || isClearIconVisible,
      [styles.isInputIconDisabled]: iconType && disabled,
    }, className);

    const inputCls = cn(styles.formInput, inputClassName, {
      [styles.isError]: (meta && meta.touched && meta.error),
      // @ts-ignore
      [styles.isWarning]: meta && meta.touched && meta.warning,
      [styles.isClearable]: isClearIconVisible,
      [styles.small]: size === 'small',
    });

    const inputProps = {
      onClick,
      onBlur, // is overrided by onBlur passed in input
      onKeyPress,
      autocomplete,
      ...input,
    };
    return (
      <FormField
        isNarrow={ isNarrow }
        isValidationFeedback={
          // @ts-ignore
          !!(meta && meta.touched && (meta.error || meta.warning))
        }
        dataTest={ dataTest }
        className={ formFieldClassName }
      >
        { label && (
          <Label htmlFor={ input.name }>
            { label }
          </Label>
        ) }
        <label
          className={ inputWrapperCls }
          onMouseOver={ this.handleHoverOn }
          onMouseLeave={ this.handleHoverOff }
        >
          { this.renderPrependComponent() }
          <input
            key="input"
            name={ name }
            type={ type }
            disabled={ disabled }
            placeholder={ placeholder }
            data-lpignore={ !isLastPassAutofillEnabled }
            autoFocus={ autoFocus }
            maxLength={ maxLength }
            minLength={ minLength }
            max={ max }
            min={ min }
            readOnly={ isReadOnly }
            value={ this.value() }
            { ...inputProps }
            className={ inputCls }
            onChange={ this.handleOnChange }
            onFocus={ this.focus }
            onBlur={ this.onBlur }
            ref={ this.nodeInput }
            autoComplete={ autocomplete }
          />
          { this.renderAppendComponent() }
          { isErrorMessage && <FormError { ...meta } /> }
        </label>
      </FormField>
    );
  }
}

Input.defaultProps = defaultProps;

export default Input;
