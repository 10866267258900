import React, { useCallback, useState } from 'react';
import FinalForm from '@/ui/shared/components/Form/FinalForm';
import validate from './validator';
import ChangePermissionsSection from '../ChangePermissionsSection';
import FormSection from '../FormSection';
import FileIcon from '@/dataroom/ui/common/FilesystemIcon/FileIcon';
import { Input } from '@dealroadshow/uikit';
import { useFilesystemContext } from '@/dataroom/application/filesystem/filesystemActions/FilesystemContext';
import { IChangePermissionsType } from '@/dataroom/domain/vo/filesystem/ChangePermissionsType';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { PermissionGroup } from '@/dataroom/domain/vo/types/PermissionGroup';
import { TFinalFormRenderProp } from '@/ui/shared/components/Form/FinalForm/interfaces';
import styles from './editFileForm.scss';

interface IProps {
  initialValues: {},
  isFetching: boolean,
  item: IFilesystemListItem,
  closeModal: () => void,
  onSubmit: (
    formData: {
      fileId: number,
      fileName: string,
      fileExtension: string,
    },
    permissionGroups: Partial<{
      [key in PermissionGroup]: number[];
    }>,
    shouldUpdate: boolean,
  ) => Promise<{ code: number } | null>,
  userHasSystemManageAccess: boolean,
  isPermissionsSectionVisible: boolean,
  isUserAdminLite: boolean,
  onShowPermissionsSection: () => void,
  name: string,
  footerRefCallback: () => void,
}

const EditFileForm = (
  {
    initialValues,
    isFetching,
    item,
    closeModal,
    onSubmit,
    userHasSystemManageAccess,
    isPermissionsSectionVisible,
    isUserAdminLite,
    onShowPermissionsSection,
    name,
    footerRefCallback,
  }: IProps,
) => {
  const { allEditPermissions: { updatePermissions } } = useFilesystemContext();
  const [permissions, setPermissions] = useState<IChangePermissionsType>({});
  const { allItems, selectedItems, disabledIds, defaultCheckboxGroupName, ...permissionGroups } = permissions;
  let shouldUpdate = false;
  const handleFormSubmit = (formData) => onSubmit(formData, permissionGroups, shouldUpdate)
    .then(closeModal).then(updatePermissions);

  const renderForm: TFinalFormRenderProp = useCallback(({
    invalid,
    pristine,
    dirtyFields,
    modifiedSinceLastSubmit,
    hasSubmitErrors,
    hasValidationErrors,
  }, {
    Field,
  }) => {
    shouldUpdate = !!Object.keys(dirtyFields).length;
    return (
      <>
        <FormSection
          pristine={ pristine }
          hasSubmitErrors={ hasSubmitErrors }
          hasValidationErrors={ hasValidationErrors }
          modifiedSinceLastSubmit={ modifiedSinceLastSubmit }
          isFetching={ isFetching }
          closeModal={ closeModal }
          isPermissionsSectionVisible={ isPermissionsSectionVisible }
          isUserAdminLite={ isUserAdminLite }
          userHasSystemManageAccess={ userHasSystemManageAccess }
          onShowPermissionsSection={ onShowPermissionsSection }
          name={ name }
          footerRefCallback={ footerRefCallback }
        >
          <FileIcon
            extension={ item.extension }
            className={ styles.fileIcon }
          />
          <div className={ styles.titleWrp }>
            Rename the file "<b>{ item.name }</b>" to:
          </div>

          <div className={ styles.inputWrp }>
            <div className={ styles.fileNameWrp }>
              <Field
                name="fileName"
                placeholder="File name"
                component={ Input }
                dataTest="editFileNameInput"
                autoFocus
              />
            </div>
            <div className={ styles.fileExtension }>
              .{ item.extension }
            </div>
          </div>
        </FormSection>
        { userHasSystemManageAccess && isPermissionsSectionVisible && (
          <ChangePermissionsSection
            invalid={ invalid }
            pristine={ pristine }
            closeModal={ closeModal }
            parentFolderId={ item.id }
            onChange={ setPermissions }
            isFetching={ isFetching }
            permissionGroups={ permissions }
            name={ name }
            footerRefCallback={ footerRefCallback }
          />
        ) }
      </>
    );
  }, [
    isFetching,
    isPermissionsSectionVisible,
    userHasSystemManageAccess,
    permissionGroups,
  ]);

  return (
    <FinalForm
      name="editFileForm"
      dataTest="editFileForm"
      initialValues={ initialValues }
      onSubmit={ handleFormSubmit }
      render={ renderForm }
      validate={ validate }
    />
  );
};

export default EditFileForm;
