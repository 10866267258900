import React, { useRef } from 'react';
import cn from 'classnames';

import { FormError, FormField } from '@dealroadshow/uikit';
import { IFinalFormFieldInputComponentProps } from '@/ui/shared/components/Form/FinalForm/interfaces';

import formatNumber from '@/Framework/dataHelpers/formatters/formatNumber';
import useTextAreaAutosize from '@/ui/shared/components/Form/Input/useTextAreaAutosize';

import styles from '@dealroadshow/uikit/dist/lib/components/Forms/Input/input.scss';
import formStyles from '@/ui/shared/components/Form/form.scss';

interface IProps extends IFinalFormFieldInputComponentProps {
  label?: React.ReactNode,
  textareaClassName?: string,
  className?: string,
  containerClassName?: string,
  useDrop?: boolean,
  isNarrow?: boolean,
  name: string,
  countdownMessage?: string,
  isEnabledCountdownMessage?: boolean,
  isErrorMessage?: boolean,
  highlightBorderOnError?: boolean,
  formFieldClassName?: string,
  dataTest: string,
  // props below are needed for height autosize
  autosize?: boolean,
  maxLength?: number,
  maxHeight?: number,
}

const Textarea = (
  {
    label,
    className,
    textareaClassName,
    containerClassName,
    meta,
    input,
    useDrop = false,
    isNarrow = false,
    name,
    countdownMessage,
    isEnabledCountdownMessage = false,
    isErrorMessage = true,
    highlightBorderOnError = true,
    dataTest,
    autosize = false,
    maxLength,
    maxHeight,
    formFieldClassName,
    ...textAreaOtherProps
  }: IProps,
) => {
  const textAreaRef = useRef(null);

  const textareaCls = cn(styles.formInputTextArea, textareaClassName, {
    [styles.withAutosize]: autosize,
    // @ts-ignore
    [styles.isError]: highlightBorderOnError && meta && meta.touched && (meta.error || meta.warning),
  });

  const inputProps = { ...input };
  const charactersRemaining = maxLength - (inputProps.value?.length || 0);
  const isPlural = charactersRemaining !== 1;
  const countdownMsg = countdownMessage || `character${ isPlural ? 's' : '' } remaining`;

  const {
    textAreaHeight,
    parentHeight,
    onChangeWithAutosize,
  } = useTextAreaAutosize(
    {
      textAreaRef,
      maxLength,
      maxHeight,
      inputProps,
      isDisabled: !autosize,
    },
  );

  if (autosize) {
    inputProps.onChange = onChangeWithAutosize;
  }

  if (!useDrop) {
    inputProps.onDragStart = () => {};
    inputProps.onDrop = () => {};
  }

  return (
    <FormField
      className={ formFieldClassName }
      isNarrow={ isNarrow }
      isValidationFeedback={ !!(meta && meta.touched && meta.error) }
      dataTest={ dataTest }
    >
      <div className={ className }>
        { label && (
          <label
            className={ formStyles.formLabel }
            htmlFor={ input.name }
          >
            { label }
          </label>
        ) }
        <div
          className={ containerClassName }
          style={ autosize ? { minHeight: parentHeight } : {} }
        >
          <textarea
            style={ autosize ? { height: textAreaHeight } : {} }
            ref={ textAreaRef }
            className={ textareaCls }
            maxLength={ maxLength }
            name={ name }
            data-test={ name }
            { ...textAreaOtherProps }
            { ...inputProps }
          />
          { isErrorMessage && <FormError { ...meta } /> }
        </div>
        { (isEnabledCountdownMessage && maxLength) && (
          <p className={ styles.textareaCharacters }> { formatNumber(charactersRemaining) } { countdownMsg } </p>
        ) }
      </div>
    </FormField>
  );
};

export default Textarea;
